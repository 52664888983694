import equal from "@superblocksteam/fast-deep-equal/es6";
import {
  Dimension,
  Padding,
  PerCornerBorderRadius,
  PerSideBorder,
  WidgetProps,
} from "@superblocksteam/shared";
import React from "react";
import { createSelector } from "reselect";
import shallowEqual from "shallowequal";
import { ReactComponent as ContainerCardStyle } from "assets/icons/common/container-card.svg";
import { ReactComponent as ContainerNoneStyle } from "assets/icons/common/container-none.svg";
import { RadioButtonGroupOption } from "components/ui/RadioButtonGroup";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  CanvasLayout,
  GridDefaults,
  WidgetType,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import {
  BASE_WIDGET_VALIDATION,
  VALIDATION_TYPES,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";
import { CanvasSelectionArena } from "legacy/pages/Editor/CanvasArenas/CanvasSelectionArena";
import { EmptyCanvasOverlay } from "legacy/pages/Editor/CanvasArenas/EmptyCanvasOverlay";
import PaddingOverlay from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { GeneratedTheme } from "legacy/themes";
import {
  DEFAULT_CONTAINER_BORDER_OBJECT,
  EMPTY_RADIUS,
  NO_BORDER_OBJECT,
} from "legacy/themes/constants";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { AppState } from "store/types";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "./BaseWidget";
import WidgetFactory, { CanvasWidgetsReduxState } from "./Factory";
import ContainerComponent from "./Shared/ContainerComponent";
import {
  getBorderThickness,
  getCanvasMinHeightFlattened,
  getWidgetDefaultPadding,
} from "./base/sizing";
import {
  marginProperty,
  paddingProperty,
  sizeSection,
  visibleProperties,
} from "./basePropertySections";
import { layoutProperties } from "./layoutProperties";
import { WidgetLayoutProps, FlattenedWidgetLayoutMap } from "./shared";
import { styleProperties } from "./styleProperties";
import type { DynamicWidgetsVisibilityState } from "legacy/selectors/visibilitySelectors";

// If someone sets border, radius or padding, we mark this as custom but the value is still set and the styles are overrides
// This is currently disabled
const showAsteriskForOverrides = false;

const shallowNoChildren: shallowEqual.Customizer<any> = (_a, _b, key) => {
  if (key === "children") {
    return true;
  }
  return undefined;
};

const isPaddingLargerThanZero = (padding: Padding | undefined) => {
  return (
    padding?.top?.value ||
    padding?.right?.value ||
    padding?.bottom?.value ||
    padding?.left?.value
  );
};

const isBorderLargerThanZero = (border: PerSideBorder | undefined) => {
  return (
    border?.top?.width ||
    border?.right?.width ||
    border?.bottom?.width ||
    border?.left?.width
  );
};

class ContainerWidget<
  T extends ContainerWidgetProps = ContainerWidgetProps,
> extends BaseWidget<T, WidgetState> {
  constructor(props: T) {
    super(props);
    this.renderChildWidget = this.renderChildWidget.bind(this);
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          // Style we have two: Card and None
          // Card: has border,radius, and padding
          // None: has no border,radius and padding
          // When this is set, we update or clear the border, radius and padding
          {
            propertyName: "containerStyle",
            label: "Container style",
            controlType: "RADIO_BUTTON_GROUP",

            optionsSelector: createSelector(
              getWidgets,
              (state: AppState, widget: WidgetProps) => widget,
              (widgets, canvas) => {
                const container = widgets[
                  canvas.parentId ?? ""
                ] as ContainerWidgetProps;

                const paddingSet = !!canvas.padding;
                const borderSet =
                  container.border != null || container.borderRadius;
                const backgroundSet = !!container.backgroundColor;

                const isCustom = paddingSet || borderSet || backgroundSet;

                const noneHasOverrides =
                  isCustom &&
                  container.containerStyle === "none" &&
                  showAsteriskForOverrides;
                const cardHasOverrides =
                  isCustom &&
                  container.containerStyle !== "none" &&
                  showAsteriskForOverrides;

                const overrides = [
                  paddingSet && "Padding",
                  borderSet && "Border",
                  backgroundSet && "Background",
                ].filter(Boolean);
                const overridesTooltip = `The following style${
                  overrides.length > 1 ? "s are" : " is"
                } overridden: ${overrides.join(", ")}`;

                const options: RadioButtonGroupOption[] = [
                  {
                    label: "Card" + (cardHasOverrides ? "*" : ""),
                    value: "card",
                    tooltip: cardHasOverrides ? overridesTooltip : undefined,
                    showTooltip: cardHasOverrides,
                    icon: <ContainerCardStyle />,
                  },
                  {
                    label: "None" + (noneHasOverrides ? "*" : ""),
                    value: "none",
                    tooltip: noneHasOverrides ? overridesTooltip : undefined,
                    showTooltip: noneHasOverrides,
                    icon: <ContainerNoneStyle />,
                  },
                ];

                return options;
              },
            ),
            helpText:
              "Applies padding, border, and background styles inherited from the theme",
            defaultValue: "card",
            isJSConvertible: false,
            isBindProperty: false,
            isTriggerProperty: false,
            getTargetWidgetId: (props) => props.children?.[0],
            getAdditionalHiddenData: {
              canvasWidgets: getWidgets,
            },
            hidden: (props, path, flags, additionalHiddenData) => {
              const parent =
                additionalHiddenData?.canvasWidgets?.[props.parentId];
              return parent && parent.type === WidgetTypes.FORM_WIDGET;
            },
            propertyCategory: PropsPanelCategory.Appearance,
          },
          // Because these properties are part of a feature flag they should not get their own sectionName
          ...layoutProperties({ isLayoutOnChild: true }),
          paddingProperty({
            getTargetWidgetId: (props) => props.children?.[0],
          }),
          marginProperty(),
        ],
      },
      {
        sectionName: "Style",
        children: styleProperties({
          backgroundColorThemeValue: ({ props }: { props: any }) => {
            const color =
              props.containerStyle === "none"
                ? "transparent"
                : "colors.neutral";

            return {
              value: color,
              treatAsNull: color === "transparent" || color == null,
            };
          },
          borderThemeValue: ({ props }: { props: any }) => {
            const isNone = props.containerStyle === "none";
            const value = isNone
              ? NO_BORDER_OBJECT
              : DEFAULT_CONTAINER_BORDER_OBJECT;
            const treatAsNull = value == null || equal(value, NO_BORDER_OBJECT);
            return {
              treatAsNull,
              value: value,
            };
          },
          defaultBorderProperty: DEFAULT_CONTAINER_BORDER_OBJECT,
          borderRadiusThemeValue: ({
            props,
            theme,
          }: {
            props: any;
            theme: any;
          }) => {
            const themeDefault = createPerCornerBorderRadius(
              theme.borderRadius,
            );
            const isNone = props.containerStyle === "none";
            const value = isNone ? undefined : themeDefault;
            const treatAsNull = value == null || equal(value, EMPTY_RADIUS);
            return {
              treatAsNull,
              value: value,
            };
          },
          defaultBorderRadiusProperty: EMPTY_RADIUS,
        }),
      },
      sizeSection({
        heightSupportsFitContent: true,
        widthSupportsFitContent: true,
        hideMargin: true,
      }),
      {
        sectionName: "Layout",
        children: [
          {
            propertyName: "shouldScrollContents",
            propertyCategory: PropsPanelCategory.Layout,
            label: "Scroll contents",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props: ContainerWidgetProps) =>
              props.height.mode === "fitContent" && props.maxHeight == null,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      borderRadius: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  shouldComponentUpdate(nextProps: ContainerWidgetProps, nextState: any) {
    // Shallow compare on props/state except it checks one level deeper for children
    if (
      shallowEqual(nextProps, this.props, shallowNoChildren) &&
      shallowEqual(nextProps.children, this.props.children) &&
      shallowEqual(nextState, this.state)
    ) {
      return false;
    }
    return true;
  }

  renderChildWidget(childData: WidgetLayoutProps): React.ReactNode {
    return WidgetFactory.createWidget(childData, this.props.appMode);
  }

  // detached widgets should be rendered outside of the container since in vstacks, they'll end up taking up space due to
  // vstack gap. This could be solved in other ways, but this is an easy solution.
  shouldRenderOutsideContainer = (child: WidgetLayoutProps) => {
    return (
      child.detachFromLayout &&
      child.type !== WidgetTypes.SECTION_WIDGET &&
      child.type !== WidgetTypes.CANVAS_WIDGET
    );
  };

  renderChildren = () => {
    return (this.props.children ?? [])
      .filter(
        (child: WidgetLayoutProps) => !this.shouldRenderOutsideContainer(child),
      )
      .map((child: WidgetLayoutProps) =>
        this.renderChildWidget({
          ...child,
          parentLayout:
            this.props.layout ??
            (this.props.type === WidgetTypes.CANVAS_WIDGET
              ? CanvasLayout.FIXED
              : undefined),
        }),
      );
  };

  renderOutsideChildren = () => {
    return (this.props.children ?? [])
      .filter((child: WidgetLayoutProps) =>
        this.shouldRenderOutsideContainer(child),
      )
      .map((child: WidgetLayoutProps) =>
        this.renderChildWidget({
          ...child,
          parentLayout:
            this.props.layout ??
            (this.props.type === WidgetTypes.CANVAS_WIDGET
              ? CanvasLayout.FIXED
              : undefined),
        }),
      );
  };

  renderAsContainerComponent(props: ContainerWidgetProps) {
    const numGrandChildren = this.props.children?.[0]?.children?.length ?? 0;

    // Avoids the container to flicker when the screen loads on preview or deployed mode
    let forceCollapse = false;
    if (
      props.type === WidgetTypes.CONTAINER_WIDGET &&
      props.appMode !== APP_MODE.EDIT &&
      numGrandChildren === 0 &&
      !isPaddingLargerThanZero(props.children?.[0]?.padding)
    ) {
      if (!isBorderLargerThanZero(props.border)) {
        // Don't show the container at all
        return null;
      }

      // Show the container with no height even on the first render
      forceCollapse = true;
    }

    return (
      <>
        <ContainerComponent
          {...props}
          forceCollapse={forceCollapse}
          hasDefaultBorder={this.props.type !== WidgetTypes.CANVAS_WIDGET}
          shouldScrollContents={
            numGrandChildren > 0 && props.shouldScrollContents // this is to prevent scroll on small, empty containers
          }
        >
          {this.renderChildren()}
        </ContainerComponent>
        {this.renderOutsideChildren()}
        {props.appMode === APP_MODE.EDIT &&
          props.type === WidgetTypes.CANVAS_WIDGET && (
            <>
              <PaddingOverlay
                widgetId={props.widgetId}
                padding={props.padding}
                parentId={props.parentId}
                appMode={props.appMode}
              />
              <CanvasSelectionArena
                widgetId={props.widgetId}
                parentId={props.parentId}
                snapColumnSpace={props.parentColumnSpace}
                snapRowSpace={props.parentRowSpace}
                layout={props.layout}
                childIds={props.children?.map((child) => child.widgetId)}
              />
              {(this.props.children ?? []).length === 0 && (
                <EmptyCanvasOverlay
                  widgetId={props.widgetId}
                  internalWidth={this.props.internalWidth}
                />
              )}
            </>
          )}
      </>
    );
  }

  getPageView() {
    return this.renderAsContainerComponent(this.props);
  }

  getWidgetType(): WidgetType {
    return WidgetTypes.CONTAINER_WIDGET;
  }

  static computeMinHeightFromProps(
    props: Omit<ContainerWidgetProps, "children">,
    widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap,
    theme: GeneratedTheme,
    appMode: APP_MODE,
    dynamicVisibility: DynamicWidgetsVisibilityState,
  ): Dimension<"px"> | undefined {
    let bottomPx = 0;
    let noWidgets = true;
    const canvases = widgets[props.widgetId]?.children?.map(
      (id) => widgets[id],
    );
    for (const canvas of canvases ?? []) {
      const canvasVisibilityState = dynamicVisibility[canvas.widgetName];
      if (
        canvasVisibilityState != null &&
        canvasVisibilityState.collapseWhenHidden &&
        !canvasVisibilityState.isVisible &&
        appMode !== APP_MODE.EDIT
      ) {
        continue;
      }
      const pxHeight = getCanvasMinHeightFlattened(canvas, widgets);
      const padding = canvas.padding ?? getWidgetDefaultPadding(theme, canvas);
      const borderWidth = getBorderThickness(props, theme, canvas) * 2;
      const pY = Padding.y(padding);
      noWidgets = pxHeight.value <= 0 && noWidgets;
      bottomPx = Math.max(
        bottomPx,
        Dimension.add(
          Dimension.add(pxHeight, pY).asFirst(),
          Dimension.px(borderWidth),
        ).value,
      );
    }
    if (noWidgets) {
      const firstChild = canvases?.[0];
      const padding = Padding.y(
        firstChild?.padding ?? getWidgetDefaultPadding(theme, firstChild),
      );
      bottomPx =
        (appMode === APP_MODE.EDIT
          ? GridDefaults.EMPTY_CONTAINER_EDIT_MODE_HEIGHT *
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT
          : 0) + padding.value;
    }

    return Dimension.px(bottomPx);
  }
}

export type ContainerWidgetProps<
  T extends WidgetLayoutProps = WidgetLayoutProps,
> = WidgetPropsRuntime & {
  children?: T[];
  shouldScrollContents?: boolean;
  borderRadius?: PerCornerBorderRadius;
  tabIcon?: string;
  tabIconPosition?: string;
  containerStyle?: "none" | "card";
};

export default ContainerWidget;
