import styled from "styled-components";
import { ReactComponent as NoFileIcon } from "assets/icons/common/no-file.svg";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { ComponentProps } from "../../components/designSystems/default/BaseComponent";

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.GREY_100};

  iframe {
    border: 0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .ant-typography {
    max-width: 150px;
    margin-top: 12px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: ${({ theme }) => theme.colors.GREY_400};
  }

  svg {
    width: 48px;
    height: 48px;
    color: ${({ theme }) => theme.colors.GREY_200};
  }
`;

const PDFViewerComponent = ({
  fileUrl,
  widgetId,
  isLoading,
}: PDFViewerComponentProps) => {
  return (
    <Wrapper
      className={isLoading ? "bp5-skeleton" : CLASS_NAMES.DEFAULT_CONTAINER}
    >
      {fileUrl ? (
        <iframe
          title={`pdf-viewer-${widgetId}`}
          src={fileUrl}
          allow="clipboard-write; encrypted-media;"
          allowFullScreen
        ></iframe>
      ) : (
        <EmptyWrapper>
          <NoFileIcon />
          <div className={CLASS_NAMES.BODY2}>
            Please provide a valid HTTPS URL or Base64.
          </div>
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};

interface PDFViewerComponentProps extends ComponentProps {
  fileUrl?: string;
  isLoading: boolean;
}

export default PDFViewerComponent;
