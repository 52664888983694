import { ApplicationScope } from "@superblocksteam/shared";
import { omit } from "lodash";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { sendInfoUINotification } from "utils/notification";
import { getComponentDimensions } from "utils/size";
import BaseWidget, { WidgetState } from "../BaseWidget";
import withMeta, { WithMeta } from "../withMeta";
import ChatComponent from "./ChatComponent";
import ChatWidgetPropertyCategories from "./ChatWidgetPropertyCategories";
import {
  ChatWidgetProps,
  PREFERRED_DATE_FIELDS,
  PREFERRED_AVATAR_FIELDS,
  PREFERRED_MESSAGE_FIELDS,
  PREFERRED_NAME_FIELDS,
  UserMessageObject,
} from "./Constants";
import type { DerivedPropertiesMap } from "../Factory";

class ChatWidget extends BaseWidget<
  WithMeta & ChatWidgetProps,
  WidgetState & {
    pendingMessages: Record<string, UserMessageObject>;
    hasLoaded: boolean;
  }
> {
  state = {
    pendingMessages: {},
    hasLoaded: false,
  };

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<ChatWidgetProps>[]
    | undefined {
    return ChatWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      messageHistory: VALIDATION_TYPES.TABLE_DATA,
      placeholderText: VALIDATION_TYPES.TEXT,
      header: VALIDATION_TYPES.TEXT,
      showPendingMessage: VALIDATION_TYPES.BOOLEAN,
      pendingStateText: VALIDATION_TYPES.TEXT,
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      lastMessage: `{{ this.messageHistory && this.messageHistory.length > 0 ? this.messageHistory[this.messageHistory.length - 1] : undefined }}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      userMessageText: "",
      userMessageRichText: "",
      userMessageImages: undefined,
    };
  }

  componentDidUpdate(prevProps: ChatWidgetProps) {
    if (!this.props.isLoading && !this.state.hasLoaded) {
      // for the chat component, we only want to show a loading state on the first load, not when
      // the message history is updated after a message is sent
      this.setState({ hasLoaded: true });
    }
  }

  handleSend = (
    message: UserMessageObject,
    callbackFn: (success: boolean) => void,
  ) => {
    const eventHandlerConfigured =
      this.props.onMessageSend != null &&
      this.props.onMessageSend.some((event) => (event as any).type != null);
    if (eventHandlerConfigured) {
      const msgKey = Date.now().toString();
      this.setState({
        pendingMessages: {
          ...this.state.pendingMessages,
          [msgKey]: message,
        },
      });
      this.props.updateWidgetMetaProperty("userMessageImages", message.images);
      this.props.updateWidgetMetaProperty(
        "userMessageRichText",
        message.richText,
      );
      const callbackId = addNewPromise((result: { success: boolean }) => {
        this.setState({
          pendingMessages: omit(this.state.pendingMessages, msgKey),
        });
        this.props.updateWidgetMetaProperty("userMessageText", "");
        this.props.updateWidgetMetaProperty("userMessageRichText", "");
        this.props.updateWidgetMetaProperty("userMessageImages", undefined);
        callbackFn(result.success);
      });
      this.props.updateWidgetMetaProperty(
        "userMessageText",
        message.plainText,
        createRunEventHandlersPayloadOptional({
          steps: this.props.onMessageSend,
          type: EventType.ON_CHAT_MESSAGE_SEND,
          currentScope: ApplicationScope.PAGE,
          callbackId,
          entityName: this.props.widgetName,
        }),
      );
    } else if (this.props.appMode === APP_MODE.EDIT) {
      sendInfoUINotification({
        message: `${this.props.widgetName} does not have an onMessageSend event handler`,
        duration: 2,
      });
      callbackFn(false); // this will put the message back into the input box
    }
  };

  getFieldName = (
    fieldName: string,
    preferredOptions: Array<string>,
    options: Array<string>,
    isRequired: boolean,
  ) => {
    if (fieldName || !isRequired) {
      return fieldName;
    }
    if (preferredOptions.length) {
      // look for prefered options in options, in order of preference
      for (let i = 0; i < preferredOptions.length; i++) {
        const bestMatch = options.find((opt) =>
          opt.toLowerCase().includes(preferredOptions[i]),
        );
        if (bestMatch) {
          return bestMatch;
        }
      }
    } else if (isRequired) {
      return options[0];
    }
    return "";
  };

  getFieldNames = () => {
    const {
      messageField,
      displayNameField,
      timestampField,
      avatarField,
      messageHistory,
    } = this.props;
    if (
      !this.props.messageHistory ||
      !Array.isArray(
        this.props.messageHistory || this.props.messageHistory.length === 0,
      )
    ) {
      return {
        messageField,
        displayNameField,
        timestampField,
        avatarField,
      };
    }
    if (typeof messageHistory[0] === "object") {
      const options = Object.keys(messageHistory[0]);

      return {
        messageField: this.getFieldName(
          messageField,
          PREFERRED_MESSAGE_FIELDS,
          options,
          true,
        ),
        displayNameField: this.getFieldName(
          displayNameField,
          PREFERRED_NAME_FIELDS,
          options,
          true,
        ),
        timestampField: this.getFieldName(
          timestampField,
          PREFERRED_DATE_FIELDS,
          options,
          false,
        ),
        avatarField: this.getFieldName(
          avatarField,
          PREFERRED_AVATAR_FIELDS,
          options,
          false,
        ),
      };
    }
    return {
      messageField,
      displayNameField,
      timestampField,
      avatarField,
    };
  };

  onMessageChange = ({ plainText, richText, images }: UserMessageObject) => {
    this.props.updateWidgetMetaProperty("userMessageText", plainText);
    this.props.updateWidgetMetaProperty("userMessageRichText", richText);
    this.props.updateWidgetMetaProperty("userMessageImages", images);
  };

  getPageView() {
    const { messageField, displayNameField, timestampField, avatarField } =
      this.getFieldNames();
    const { componentWidth } = getComponentDimensions(this.props);
    return (
      <ChatComponent
        messages={
          Array.isArray(this.props.messageHistory)
            ? this.props.messageHistory
            : []
        }
        avatarField={avatarField}
        timestampField={timestampField}
        displayNameField={displayNameField}
        timestampFormat={this.props.timestampFormat}
        messageField={messageField}
        onMessageSend={this.handleSend}
        enableImages={this.props.enableImages}
        enableEnterToSend={this.props.enableEnterToSend}
        enableSearch={this.props.enableChatSearch}
        enableEmojis={this.props.enableEmojis}
        placeholderText={this.props.placeholderText}
        header={this.props.header}
        showPendingState={this.props.enablePendingState}
        pendingStateText={this.props.pendingStateText}
        pendingStateTimeout={this.props.pendingStateTimeout}
        pendingMessages={
          this.props.showPendingMessage ? this.state.pendingMessages : {}
        }
        onMessageChange={this.onMessageChange}
        isLoading={!this.state.hasLoaded && this.props.isLoading}
        isDisabled={this.props.isDisabled === true}
        width={componentWidth}
        headerProps={this.props.headerProps}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "CHAT_WIDGET";
  }
}

export default ChatWidget;
export const ConnectedChatWidget = withMeta(ChatWidget);
