import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Skeleton from "legacy/components/utils/Skeleton";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { retryPromise } from "legacy/utils/Utils";
import { DiffMethod } from "legacy/widgets/DiffWidget/DiffMethod";
import { getComponentDimensions } from "utils/size";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import withMeta, { WithMeta } from "../withMeta";
import DiffWidgetPropertyCategories from "./DiffWidgetPropertyCategories";
import { derived } from "./derived";
import type { AppState } from "store/types";

const ReactDiffViewer = lazy(() =>
  retryPromise(
    () =>
      import(
        /* webpackChunkName: "reactDiff" */ "legacy/widgets/DiffWidget/DiffViewer"
      ),
  ),
);

const StyledWrapper = styled.div<{
  height: number;
}>`
  height: ${(props) => props.height}px;
  font-family: monospace;

  && pre {
    line-height: 16px;
    overflow: hidden;
  }

  overflow-y: auto;
  overflow-x: hidden;
`;

class DiffWidget extends BaseWidget<DiffWidgetProps, DiffWidgetState> {
  constructor(props: DiffWidgetProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<DiffWidgetProps>[]
    | undefined {
    return DiffWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      originalText: VALIDATION_TYPES.TEXT,
      newText: VALIDATION_TYPES.TEXT,
    };
  }

  static getDerivedPropertiesMap() {
    return {
      diffs: `{{(${derived.diffs})()}}`,
    };
  }

  getPageView() {
    const { componentHeight } = getComponentDimensions(this.props);
    return (
      <Suspense fallback={<Skeleton />}>
        <StyledWrapper
          height={componentHeight}
          className={CLASS_NAMES.DEFAULT_CONTAINER}
          // manual override to match the diff viewer's built-in dark mode
          // in the future, we shuold update the diff viewer to conform to our theme
          style={
            this.props.generatedTheme.mode === "DARK"
              ? { backgroundColor: "#2f323e" }
              : undefined
          }
        >
          {this.props.isLoading ? (
            <Skeleton />
          ) : (
            <ReactDiffViewer
              leftTitle={this.props.originalLabel}
              rightTitle={this.props.newLabel}
              oldValue={
                typeof this.props.originalText === "string"
                  ? this.props.originalText
                  : JSON.stringify(this.props.newText)
              }
              newValue={
                typeof this.props.newText === "string"
                  ? this.props.newText
                  : JSON.stringify(this.props.newText)
              }
              showDiffOnly={this.props.showDiffOnly}
              extraLinesSurroundingDiff={2}
              splitView={true}
              compareMethod={this.props.compareMethod}
              useDarkTheme={this.props.generatedTheme.mode === "DARK"}
            />
          )}
        </StyledWrapper>
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return WidgetTypes.DIFF_WIDGET;
  }
}

export interface DiffWidgetProps extends WidgetPropsRuntime, WithMeta {
  originalLabel?: string;
  originalText?: string;
  newLabel?: string;
  newText?: string;
  compareMethod?: DiffMethod;
  showDiffOnly?: boolean;
  isVisible?: boolean;
  generatedTheme: GeneratedTheme;
}

interface DiffWidgetState extends WidgetState {
  isLoading: boolean;
}

const mapStateToProps = (state: AppState) => ({
  generatedTheme: selectGeneratedTheme(state),
});

export default DiffWidget;
export const ConnectedDiffWidget = connect(mapStateToProps)(
  withMeta(DiffWidget),
);
