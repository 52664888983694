import { Suspense } from "react";
import Skeleton from "legacy/components/utils/Skeleton";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import log from "utils/logger";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import withMeta from "../withMeta";
import IFrameComponent from "./IFrameComponent";
import IFramePropertyCategories from "./IFramePropertyCategories";
import { formatEmbeddedAppUrl } from "./formatEmbeddedAppUrl";

class IFrameWidget extends BaseWidget<IFrameWidgetProps, WidgetState> {
  static getNewPropertyPaneConfig(): PropertyPaneConfig[] | undefined {
    return IFramePropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      source: VALIDATION_TYPES.URL_OR_BASE64,
      srcDoc: VALIDATION_TYPES.TEXT,
    };
  }

  handleMessageReceive = (event: MessageEvent) => {
    try {
      const steps = (this.props.onMessageReceived ?? [])?.flatMap((step) => {
        return step;
      });
      super.runEventHandlers({
        steps,
        type: EventType.ON_IFRAME_MESSAGE_RECEIVED,
        additionalNamedArguments: { currentMessage: event.data },
      });
    } catch (error) {
      log.debug("Error parsing onMessageReceived action");
      log.debug(error);
    }
  };

  getPageView() {
    const { widgetId, source, srcDoc, isLoading } = this.props;

    return (
      <Suspense fallback={<Skeleton />}>
        <IFrameComponent
          widgetId={widgetId}
          source={formatEmbeddedAppUrl(source)}
          srcDoc={srcDoc}
          isLoading={isLoading}
          onMessageReceived={this.handleMessageReceive}
        />
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return WidgetTypes.IFRAME_WIDGET;
  }
}

export interface IFrameWidgetProps extends WidgetPropsRuntime {
  source: string;
  srcDoc?: string;
  onMessageReceived?: MultiStepDef;
}

export default IFrameWidget;
export const ConnectedIFrameWidget = withMeta(IFrameWidget);
