import { get } from "lodash";
import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, VerticalAlign } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import {
  DATE_INPUT_FORMATS,
  DATE_OUTPUT_FORMATS,
  NUMBER_FORMATTING_OPTIONS,
  formatCurrency,
  formatDate,
  formatNumber,
} from "legacy/utils/FormatUtils";
import {
  textColorProperty,
  textStyleCombinedProperty,
} from "legacy/widgets/styleProperties";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { DerivedPropertiesMap } from "../Factory";
import {
  iconPositionProperty,
  iconProperty,
  maximumFractionDigitsProperty,
  minimumFractionDigitsProperty,
} from "../appearanceProperties";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { TextComponentWithLayoutManaged } from "./TextComponent";
import {
  TextStyle,
  TextWidgetProps,
  legacyTextWidgetTextStyleToTypographyMap,
  getTextStyleVariant,
  DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT,
  getMissingTextStyleVariant,
} from "./index";

class TextWidget extends BaseWidget<TextWidgetProps, WidgetState> {
  previousText?: string;
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        sectionCategory: PropsPanelCategory.Content,
        children: [
          {
            propertyName: "text",
            helpText: "The value to display",
            label: "Text",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter text or HTML",
            forceVertical: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "textType",
            helpText: "The data type of the value being displayed",
            label: "Data type",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Plain text",
                value: "text",
              },
              {
                label: "Number",
                value: "number",
              },
              {
                label: "Currency",
                value: "currency",
              },
              {
                label: "Date",
                value: "date",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "currency",
            label: "Currency code",
            helpText: "The three letter ISO 4217 currency code",
            controlType: "DROP_DOWN",
            hidden: (props: TextWidgetProps) => props.textType !== "currency",
            defaultValue: "USD",
            options: CurrencyList.map((item) => ({
              label: item,
              value: item,
            })),
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "notation",
            label: "Number format",
            controlType: "DROP_DOWN",
            hidden: (props: TextWidgetProps) =>
              props.textType !== "number" && props.textType !== "currency",
            defaultValue: "standard",
            options: NUMBER_FORMATTING_OPTIONS,
            isBindProperty: false,
            isTriggerProperty: false,
            helpText: "The display format of the number",
            propertyCategory: PropsPanelCategory.Appearance,
          },
          minimumFractionDigitsProperty({
            hidden: (props: TextWidgetProps) =>
              props.textType !== "number" && props.textType !== "currency",
          }),
          maximumFractionDigitsProperty({
            hidden: (props: TextWidgetProps) =>
              props.textType !== "number" && props.textType !== "currency",
          }),
          {
            propertyName: "dateInputFormat",
            label: "Value format",
            controlType: "DROP_DOWN",
            options: DATE_INPUT_FORMATS,
            hidden: (props: TextWidgetProps) => props.textType !== "date",
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "dateOutputFormat",
            propertyCategory: PropsPanelCategory.Appearance,
            label: "Display format",
            controlType: "DROP_DOWN",
            options: DATE_OUTPUT_FORMATS,
            hidden: (props: TextWidgetProps) => props.textType !== "date",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          textStyleCombinedProperty({
            label: "Style",
            textStyleParentDottedPath: "textProps",
            enableTextColorConfig: false,
            defaultValueFn: {
              variant: ({ props }: { props: TextWidgetProps }) => {
                if (
                  props.textStyle != null &&
                  props.textProps?.textStyle == null
                ) {
                  return legacyTextWidgetTextStyleToTypographyMap[
                    props.textStyle as TextStyle
                  ];
                }
                return DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT;
              },
            },
          }),
          textColorProperty({
            textStyleParentDottedPath: "textProps",
            isJSConvertible: true,
            updateHook: ({ propertyValue }: { propertyValue: any }) => {
              // Keep the legacy props.textColor in sync with textProps.textColor.default so that if
              // the typography flag is turned on and then off, the text component's color will not changed
              return [
                {
                  propertyPath: "textColor",
                  propertyValue,
                },
              ];
            },
            defaultValueFn: ({ props }) => {
              if (
                props.textProps?.textStyle?.textColor?.default == null &&
                props.textColor != null
              ) {
                return props.textColor;
              }
              return null; // let color picker figure it out
            },
            themeValue: ({ theme, props }) => {
              let variant = getTextStyleVariant(props);

              if (!variant) {
                variant = getMissingTextStyleVariant(props);
              }
              if (variant === SB_CUSTOM_TEXT_STYLE) {
                return undefined;
              }
              // check if a custom variant has been deleted, if so fallback
              if (variant && !get(theme.typographies, variant)) {
                variant = DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT;
              }

              return {
                value: `typographies.${variant}.textColor.default`,
                treatAsNull: false,
              };
            },
          }),
        ],
      },
      sizeSection({
        heightSupportsFitContent: true,
        widthSupportsFitContent: true,
      }),
      {
        sectionName: "Icons",
        sectionCategory: PropsPanelCategory.Appearance,
        children: [iconProperty(), iconPositionProperty()],
      },
      {
        sectionName: "Style",
        sectionCategory: PropsPanelCategory.Appearance,
        children: [
          {
            propertyName: "textAlign",
            helpText:
              "The horizontal alignment of the text relative to the component width",
            label: "Horizontal align",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                icon: "LEFT_ALIGN",
                value: "LEFT",
              },
              {
                icon: "CENTER_ALIGN",
                value: "CENTER",
              },
              {
                icon: "RIGHT_ALIGN",
                value: "RIGHT",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "verticalAlign",
            helpText:
              "The vertical alignment of the text relative to the component height",
            label: "Vertical align",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                icon: "VERTICAL_AUTO",
                value: VerticalAlign.AUTO,
              },
              {
                icon: "VERTICAL_TOP",
                value: VerticalAlign.TOP,
              },
              {
                icon: "VERTICAL_CENTER",
                value: VerticalAlign.CENTER,
              },
              {
                icon: "VERTICAL_BOTTOM",
                value: VerticalAlign.BOTTOM,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props: TextWidgetProps) =>
              props.height?.mode === "fitContent",
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "shouldScroll",
            helpText:
              "Whether the user can scroll vertically or horizontally to acess any overflow content. If disabled, content will clip",
            propertyCategory: PropsPanelCategory.Layout,
            label: "Scroll overflow",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      text: VALIDATION_TYPES.TEXT,
      textStyle: VALIDATION_TYPES.TEXT,
      textType: VALIDATION_TYPES.TEXT,
      shouldScroll: VALIDATION_TYPES.BOOLEAN,
      minimumFractionDigits: VALIDATION_TYPES.FRACTION_DIGITS,
      maximumFractionDigits: VALIDATION_TYPES.FRACTION_DIGITS,
      icon: VALIDATION_TYPES.ICONS,
    };
  }

  componentDidUpdate(prevProps: TextWidgetProps) {
    if (prevProps.text !== this.props.text) {
      // we don't need to rerender on previousText changing because it's only used in error logging
      this.previousText = prevProps.text;
    }
    super.componentDidUpdate(prevProps);
  }

  getText() {
    switch (this.props.textType) {
      case "number":
        return formatNumber(
          this.props.text,
          this.props.notation,
          this.props.minimumFractionDigits,
          this.props.maximumFractionDigits,
        );
      case "currency":
        return formatCurrency(
          this.props.text,
          this.props.currency,
          this.props.notation,
          this.props.minimumFractionDigits,
          this.props.maximumFractionDigits,
        );
      case "date":
        return formatDate(
          this.props.text,
          this.props.dateInputFormat,
          this.props.dateOutputFormat,
        );
      default:
        return this.props.text;
    }
  }

  getErrorInformation() {
    return {
      text: this.props.text,
      previousText: this.previousText,
    };
  }

  getPageView() {
    return <TextComponentWithLayoutManaged {...this.props} />;
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.text }}`,
    };
  }

  getWidgetType(): WidgetType {
    return "TEXT_WIDGET";
  }
}

export default TextWidget;
