import { Suspense } from "react";
import Skeleton from "legacy/components/utils/Skeleton";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import PDFViewerComponent from "./PDFViewerComponent";
import PDFViewerPropertyCategories from "./PDFViewerPropertyCategories";

class PDFViewerWidget extends BaseWidget<PDFViewerWidgetProps, WidgetState> {
  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<PDFViewerWidgetProps>[]
    | undefined {
    return PDFViewerPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      fileUrl: VALIDATION_TYPES.URL_OR_BASE64,
    };
  }

  getPageView() {
    return (
      <Suspense fallback={<Skeleton />}>
        <PDFViewerComponent
          widgetId={this.props.widgetId}
          fileUrl={this.props.fileUrl}
          isLoading={this.props.isLoading}
        />
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return "PDF_VIEWER_WIDGET";
  }
}

export interface PDFViewerWidgetProps extends WidgetPropsRuntime {
  fileUrl?: string;
}

export default PDFViewerWidget;
