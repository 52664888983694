import { Dimension } from "@superblocksteam/shared";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { isDraggingInContainer } from "legacy/selectors/dndSelectors";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { getDynamicVisibilityWidgets } from "legacy/selectors/visibilitySelectors";
import { GeneratedTheme } from "legacy/themes";
import { useAppSelector } from "store/helpers";
import { WidgetProps } from "./BaseWidget";
import WidgetFactory from "./Factory";
import type { DynamicWidgetsVisibilityState } from "legacy/selectors/visibilitySelectors";

export type WithHeightOverride = {
  computedHeight?: number;
};

// Use this function for computeMinHeightFromProps if you want to use the default
// height calculation logic even though you have extended ContainerWidget.
export const USE_DEFAULT_COMPUTE_HEIGHT = Object.freeze(() => undefined);

/**
 * The function calculates the height to expand to based on the number of rows in a drop target and the
 * height of its children widgets.
 * @returns the height calculated from the children of the widget specified by
 * `expandInfo.dropTargetId`.
 */
const heightWithDropTarget = (
  dropTargetId: string,
  dropTargetRows: number,
  widgets: CanvasWidgetsReduxState,
  widgetProps: WidgetProps,
  theme: GeneratedTheme,
  appMode: APP_MODE,
  dymamicVisibility: DynamicWidgetsVisibilityState,
): Dimension<"px"> | undefined => {
  const fakeWidget = {
    widgetId: "fake-widget",
    widgetName: "fake-widget",
    type: "TEXT_WIDGET",
    top: Dimension.gridUnit(0),
    left: Dimension.gridUnit(0),
    width: Dimension.gridUnit(0),
    height: Dimension.gridUnit(dropTargetRows),
    parentId: dropTargetId,
    appMode: APP_MODE.EDIT,
    isLoading: false,
  } satisfies FlattenedWidgetProps;

  const isVStack = widgets[dropTargetId].layout === "VSTACK";
  const children = isVStack
    ? ["fake-widget"]
    : [...(widgets[dropTargetId].children ?? []), "fake-widget"];

  const widgetsPlusFake = {
    ...widgets,
    // Add the fake widget to the drop target's children
    [dropTargetId]: {
      ...widgets[dropTargetId],
      children,
    },
    // Add the fake widget to the widgets map
    "fake-widget": fakeWidget,
  };
  return WidgetFactory.getWidgetComputedHeight(
    widgetProps,
    widgetsPlusFake,
    theme,
    appMode,
    dymamicVisibility,
  );
};

const useWidgetHeightFromProps = (widgetProps: WidgetProps) => {
  const widgets = useSelector(getWidgets);
  const theme = useSelector(selectGeneratedTheme);
  const appMode = useSelector(getAppMode) ?? APP_MODE.PUBLISHED;
  const expandInfo = useAppSelector((state) =>
    isDraggingInContainer(state, widgetProps.widgetId),
  );
  const dynamicVisibility = useSelector(getDynamicVisibilityWidgets);

  const calculatedHeightPx = useMemo(() => {
    try {
      const heightPx = WidgetFactory.getWidgetComputedHeight(
        widgetProps,
        widgets,
        theme,
        appMode,
        dynamicVisibility,
      );
      if (heightPx !== undefined) {
        const possibleHeight = expandInfo.shouldExpand
          ? (heightWithDropTarget(
              expandInfo.dropTargetId,
              expandInfo.dropTargetRows,
              widgets,
              widgetProps,
              theme,
              appMode,
              dynamicVisibility,
            ) ?? Dimension.px(0))
          : Dimension.px(0);

        return (
          Dimension.max(heightPx, possibleHeight).value - WIDGET_PADDING * 2
        );
      }
    } catch (e) {
      return undefined;
    }
  }, [widgetProps, widgets, theme, appMode, expandInfo, dynamicVisibility]);

  return calculatedHeightPx;
};

type Output<P extends WidgetProps> = P & WithHeightOverride;

const withComputedHeight = <P extends WidgetProps>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<Output<P>>>,
) => {
  const WithHeightCalculation = (props: Output<P>) => {
    const computedHeight = useWidgetHeightFromProps(props);
    return <WrappedComponent {...props} computedHeight={computedHeight} />;
  };
  WithHeightCalculation.displayName = `WithHeightCalculation(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return WithHeightCalculation;
};

export default withComputedHeight;
